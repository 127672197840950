<template>
  <div>
    <v-form ref="form">
      <BaseGrid :items="data.fields">
        <template #item="{ item }">
          <BaseField v-model="formData[item.label]" v-bind="item" :disabled="disabled" />
        </template>
      </BaseGrid>
      <AlertMessage :success="success" :error="error" />
      <div class="d-flex justify-center">
        <CmsButton
          :loading="loading"
          :value="data.submit"
          :disabled="disabled"
          @click="onSubmit()"
        />
        <CmsButton :value="data.cancel" :disabled="disabled" @click="onCancel()" />
      </div>
    </v-form>
  </div>
</template>

<script>
import Base from './Base.vue';
import CmsButton from './Button.vue';
import { Form } from '@/services/form';
import AlertMessage from '../../components/Core/AlertMessage.vue';
const form = new Form();
export default {
  extends: Base,
  components: {
    CmsButton,
    AlertMessage
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formData: {},
      success: '',
      error: '',
      loading: false
    };
  },
  methods: {
    async onSubmit() {
      this.success = '';
      this.error = '';
      this.loading = true;
      try {
        const data = {
          ...this.formData,
          form: this.data.key || '',
          widgetID: this.id || ''
        };
        console.log(data);
        // await form.submit(data);
        try {
          await form.email({ ...this.data, formData: data, axios: this.$axios });
        } catch (error) {
          console.warn(error);
        }
        this.$refs.form.reset();
        this.success = this.data.successMessage || 'Form submitted!';
      } catch (error) {
        this.error = error.message;
      }
      this.loading = false;
      setTimeout(() => {
        this.error = '';
        this.success = '';
      }, 5000);
    },
    onCancel() {
      this.formData = {};
    }
  }
};
</script>

<style lang="scss">
</style>
