import { getFirestore, getCurrentTimestamp } from './firebase';
const firestore = getFirestore();

import { CMS } from './cms';

import config from '../config';
import { parseFormat } from '../utils';
const cms = new CMS();

class Form {
  constructor() {
    this.ref = firestore.collection(config.firestore.form);
  }

  submit(data) {
    const dataFormatted = {
      ...data,
      createdAt: getCurrentTimestamp(),
      updatedAt: getCurrentTimestamp()
    };
    return this.ref.add(dataFormatted);
  }

  async email({ email, emailMessage, emailSubject, formData, axios }) {
    if (!email) {
      return;
    }
    const html = parseFormat(emailMessage, formData);
    const subject = parseFormat(emailSubject, formData);

    const contactUsEmamilQ = await cms.get({
      key: config.keys.contactUsEmails
    });
    if (contactUsEmamilQ.docs.length) {
      const contactUsEmailsID = contactUsEmamilQ.docs[0].id;

      const data = {
        html,
        subject,
        to: {
          document: contactUsEmailsID,
          collection: config.firestore.cms
        },
        from: `${config.appName}<${config.email}>`
      };
      await axios.post(config.api().email, data);
    }
  }
}

export { Form };
