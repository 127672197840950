
<template>
  <div v-if="data" :class="`text-${data.align || ''}`">
    <div v-if="data.tooltip">
      <BaseTooltip bottom :color="data.tooltipColor">
        <template #activator="{ on }">
          <v-btn
            v-bind="{ ...data, ...attrs, ...sizes, ...$attrs }"
            v-on="on"
            @click="$emit('click')"
          >
            <v-icon v-if="data.prependIcon">{{ data.prependIcon }}</v-icon>
            {{ data.content }}
            <v-icon v-if="data.appendIcon">{{ data.appendIcon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ data.tooltip }}</span>
      </BaseTooltip>
    </div>

    <v-btn
      v-else
      v-bind="{ ...data, ...attrs, ...sizes, ...$attrs }"
      @click="$emit('click')"
    >
      <v-icon v-if="data.prependIcon">{{ data.prependIcon }}</v-icon>
      {{ data.content }}
      <v-icon v-if="data.appendIcon">{{ data.appendIcon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Base from './Base.vue'
export default {
  extends: Base,
  computed: {
    sizes() {
      const res = {}
      if (this.data) {
        const size = this.data.size
        res['x-small'] = size === 'x-small'
        res.small = size === 'small'
        res.large = size === 'large'
        res['x-large'] = size === 'x-large'
      }
      return res
    },
    attrs() {
      if (this.data.attrs) {
        try {
          return JSON.parse(this.data.attrs)
        } catch {
          return {}
        }
      } else {
        return {}
      }
    },
  },
}
</script>

<style lang="scss">
</style>